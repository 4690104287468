import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import Navbar from '../components/navbar'
import Img from "gatsby-image"

class ProjectsPage extends React.Component {
  render() {
    const siteTitle = "Sean Cheng"

    return (
      <Layout location={this.props.location} title={siteTitle}>  
      
     <SEO title="Projects" />

    <h2>Projects</h2>
    
    <p><b><a href="https://mconsultinglist.com/">MC List</a></b><br></br>
    After getting frustrated with the difficulty of knowing exactly when management consulting roles opened up when I was recruiting, 
    I decided to build MC List— a job board for top full-time and internship consulting roles that notifies applicants exactly when roles are open for applications. 
    I built and scaled MC List to 10,000+ users and got  the site featured by Stanford University and Haverford College Career Services. 
    MC List was eventually acquired by <a href="https://www.tryexponent.com/blog/exponent-has-acquired-mclist">Exponent</a>.
    </p>


    <p><b><a href="https://www.cs.swarthmore.edu/courses/CS17/S22/?fbclid=IwAR0OGrkbCJIgseKUvohNNm61l2PkYafLPCOYUodtVaW6CFO_Mm4BmUxVX6o">CS 17: Foundations of Technical Entrepreneurship</a></b><br></br>
    I wrote the curriculum and was the lead instructor for the first-ever startup entrepreneurship class at Swarthmore. 
    As part of the class, we hosted guest speakers like Aaron Levie (co-founder of Box), Chirag Chotalia (Partner at Threshold Ventures), and Julie Bornstein (founder of THE YES). 
    This class led to numerous students starting their own successful ventures and has since spawned an annual entrepreneurship class being taught as part of the Swarthmore computer science department.
    </p>

    <p><b><a href="https://www.amazon.com/50-Successful-Swarthmore-Application-Essays-ebook/dp/B08MYGWPCD/ref=sr_1_1?crid=HSM8XIHB8PRL&keywords=50+successful+swarthmore&qid=1678210565&sprefix=50+successsful+swarthmore+%2Caps%2C149&sr=8-1">50 Successful Swarthmore Application Essays</a></b><br></br>
    I worked with one of my closest friends in college to write and publish a compilation of 50 Swarthmore College application essays. 
    Had a lot of fun interviewing high schoolers to understand what sorts of advice they needed, talking to admissions officers to understand what they were looking for, and building out the sales and marketing funnel for the book. 
    You can find the book on <a href="https://www.amazon.com/50-Successful-Swarthmore-Application-Essays-ebook/dp/B08MYGWPCD/ref=sr_1_1?crid=HSM8XIHB8PRL&keywords=50+successful+swarthmore&qid=1678210565&sprefix=50+successsful+swarthmore+%2Caps%2C149&sr=8-1">Amazon</a> and at the Swarthmore College book store.
    </p>

      </Layout>

    )
  }
}

export default ProjectsPage